import { constants } from '../utils'
import base from './base'

const { headers, url } = base

const create = async (groupStudentId, degree, curriculum) => {
  const body = JSON.stringify({
    group_student_id: groupStudentId,
    degree_id: degree,
    curriculum_id: curriculum,
  })

  return fetch(url(constants.api.renewEnrollment.create), {
    method: 'POST',
    headers: headers(),
    body,
  })
}

const cancel = async groupStudentId =>
  fetch(url(constants.api.renewEnrollment.cancel(groupStudentId)), {
    method: 'POST',
    headers: headers(),
  })

const get = async (user, password) => {
  const body = JSON.stringify({
    user,
    password,
  })

  return fetch(url(constants.api.renewEnrollment.login), {
    method: 'POST',
    headers: headers(),
    body,
  })
}

const nextOffers = async groupStudentId =>
  fetch(url(constants.api.renewEnrollment.nextOffers(groupStudentId)), {
    method: 'GET',
    headers: headers(),
  })

const getPdf = async groupStudentId =>
  fetch(url(constants.api.renewEnrollment.pdf(groupStudentId)), {
    method: 'GET',
    headers: { 'Content-Type': 'multipart/form-data' },
  })

const subscriptionApi = {
  cancel,
  get,
  getPdf,
  nextOffers,
  create,
}

export default subscriptionApi
