const MULTIPLE = 4
const PHYSICAL = 6
const DEAFBLINDNESS = 7
const HEARING_LOSS = 8
const DEAFNESS = 9
const LOW_VISION = 10
const BLINDNESS = 11
const MONOCULAR_VISION = 59
const WHEELCHAIR = 60

const RETT = 1
const ASPERGER = 2
const AUTISM = 3
const PSYCHOSIS = 13

const GIFTEDNESS = 15

const incompatible = {
  [DEAFBLINDNESS]: [
    HEARING_LOSS,
    DEAFNESS,
    LOW_VISION,
    BLINDNESS,
    MONOCULAR_VISION,
  ],
  [HEARING_LOSS]: [DEAFBLINDNESS, DEAFNESS],
  [DEAFNESS]: [DEAFBLINDNESS, HEARING_LOSS, BLINDNESS],
  [LOW_VISION]: [DEAFBLINDNESS, BLINDNESS],
  [BLINDNESS]: [DEAFBLINDNESS, DEAFNESS, LOW_VISION, MONOCULAR_VISION],
  [MONOCULAR_VISION]: [DEAFBLINDNESS, BLINDNESS],
}

const update = (acc, value, checked) => {
  let curr

  // add ou remove
  if (checked) {
    curr = Array.from(new Set([...acc, value]))
  } else {
    curr = acc.filter(i => i !== value)
  }

  // checa incompatibilidades e remove tudo que for incompativel
  if (incompatible[value]) {
    curr = curr.filter(i => !incompatible[value].includes(i))
  }

  // desmarca cadeirante quando desmarca deficiencia fisica
  if (value === PHYSICAL && !checked) {
    curr = curr.filter(i => i !== WHEELCHAIR)
  }

  // marca deficiencia fisica quando cadeirante
  if (value === WHEELCHAIR && checked && !curr.includes(PHYSICAL)) {
    curr = Array.from(new Set([...curr, PHYSICAL]))
  }

  // desmarca demais disorders quando desmarca autismo
  if (value === AUTISM && !checked) {
    curr = curr.filter(i => ![RETT, ASPERGER, PSYCHOSIS].includes(i))
  }

  // marca autismo ao marcar outras disorders
  if (
    [RETT, ASPERGER, PSYCHOSIS].includes(value) &&
    checked &&
    !curr.includes(AUTISM)
  ) {
    curr = Array.from(new Set([...curr, AUTISM]))
  }

  // marca ou desmarca multipla - deve ser o ultimo passo
  if (
    curr.filter(
      i =>
        ![
          MULTIPLE,
          PHYSICAL,
          WHEELCHAIR,
          RETT,
          ASPERGER,
          AUTISM,
          PSYCHOSIS,
          GIFTEDNESS,
        ].includes(i)
    ).length +
      (curr.some(i => [PHYSICAL, WHEELCHAIR].includes(i)) ? 1 : 0) >
    1
  ) {
    curr = Array.from(new Set([...curr, MULTIPLE]))
  } else {
    curr = curr.filter(i => i !== MULTIPLE)
  }

  return curr
}

const disabilities = { update }
export default disabilities
