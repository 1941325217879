import { publicCallApi } from '../api'

const getAllPublicCallDegrees = async () => {
  try {
    const response = await publicCallApi.getAllPublicCallDegrees()

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      const degrees = responseBody.data.map(({ attributes: degree }) => ({
        value: degree.ref,
        label: degree.name,
        from: degree.from,
        to: degree.to,
      }))

      return {
        success: true,
        payload: { degrees },
      }
    }
  } catch {}

  return { success: false, payload: { degrees: [] }, error: 'UnhandledError' }
}

const getAvailableCities = async degree => {
  try {
    const response = await publicCallApi.getCitiesByDegree(degree)

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      const cities = responseBody.data.map(({ attributes: city }) => ({
        value: city.ref,
        label: city.label,
      }))

      return {
        success: true,
        payload: { cities },
      }
    }
  } catch {}

  return { success: false, payload: { cities: [] }, error: 'UnhandledError' }
}

const getAvailableSchools = async (degree, city, exceptOfferIds, zipcode) => {
  try {
    const response = await publicCallApi.getSchoolsByDegreeAndCity(
      degree,
      city,
      exceptOfferIds,
      zipcode
    )

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      const schools = responseBody.data.map(({ attributes: school }) => ({
        value: school.ref,
        label: school.name,
      }))

      return {
        success: true,
        payload: { schools },
      }
    }
  } catch {}

  return { success: false, payload: { schools: [] }, error: 'UnhandledError' }
}

const getAvailableShifts = async (degree, school, exceptOfferIds) => {
  try {
    const response = await publicCallApi.getShiftsByDegreeAndSchool(
      degree,
      school,
      exceptOfferIds
    )

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      const shifts = responseBody.data.map(i => i.attributes)

      return {
        success: true,
        payload: { shifts },
      }
    }
  } catch {}

  return { success: false, payload: { shifts: [] }, error: 'UnhandledError' }
}

const getAvailableCurriculums = async (
  degree,
  school,
  shift,
  exceptOfferIds
) => {
  try {
    const response =
      await publicCallApi.getCurriculumsByDegreeAndSchoolAndShift(
        degree,
        school,
        shift,
        exceptOfferIds
      )

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      const curriculums = responseBody.data.map(
        ({ id, attributes: curriculum }) => ({
          ...curriculum,
          id: parseInt(id),
        })
      )

      return {
        success: true,
        payload: { curriculums },
      }
    }
  } catch {}

  return {
    success: false,
    payload: { curriculums: [] },
    error: 'UnhandledError',
  }
}

const getSchoolsQuestions = async () => {
  try {
    const response = await publicCallApi.getSchoolsQuestions()

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      const questions = responseBody.data.map(
        ({ id: questionId, attributes: question }) => ({
          ...question,
          id: parseInt(questionId),
          options: (question.options.data || []).map(
            ({ id: optionId, attributes: option }) => ({
              ...option,
              id: parseInt(optionId),
              label: option.title,
            })
          ),
        })
      )

      return {
        success: true,
        payload: { questions },
      }
    }
  } catch {
    return { error: 'UnhandledError' }
  }
}

const addressService = {
  getAvailableCities,
  getAvailableSchools,
  getAvailableShifts,
  getAvailableCurriculums,
  getSchoolsQuestions,
  getAllPublicCallDegrees,
}

export default addressService
