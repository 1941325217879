import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'

import { Main } from '../../../../components/Layout'
import { FormControl } from '../../../../components/Forms'
import {
  Error,
  Label,
  LoadingButton,
  Recaptcha,
  Subtitle,
} from '../../../../components'
import { Buttons } from '../../../../components/Steps'
import { constants, strings } from '../../../../utils'

const { lowcase } = strings

const useStyles = makeStyles(() => ({
  notice: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '16px',
  },
  textCenter: {
    textAlign: 'center',
  },
  center: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    margin: '24px 0 0',
  },
  error: {
    marginBottom: '16px',
  },
  margin: {
    marginTop: '12px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    padding: '30px',
  },
}))

const Form = ({ loading, notFound, tryToLogin }) => {
  const classes = useStyles()

  const [recaptcha, setRecaptcha] = useState('')
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')

  const shouldDisableSearchButton = () =>
    !recaptcha || `${user}`.length < 4 || `${password}`.length < 4 || loading

  return (
    <>
      <Main className={classes.main}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Subtitle>
              Preencha os campos abaixo com dados de acesso do aluno para
              realizar a solicitação de rematrícula para o próximo ano letivo
            </Subtitle>
          </Grid>

          {notFound && (
            <Grid item xs={12} className={classes.error}>
              <Error>
                Não foi possível encontrar nenhuma matrícula ativa para os dados
                informados. Verifique os dados inseridos e tente novamente.
              </Error>
            </Grid>
          )}

          <Grid item md={6} xs={12}>
            <FormControl>
              <TextField
                autoFocus
                type="user"
                disabled={loading}
                label="Usuário"
                name="user"
                value={user || ''}
                variant="outlined"
                onChange={e => setUser(lowcase(e.target.value))}
              />
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12}>
            <FormControl>
              <TextField
                type="password"
                disabled={loading}
                label="Senha"
                name="password"
                value={password || ''}
                variant="outlined"
                onChange={e => setPassword(lowcase(e.target.value))}
              />
            </FormControl>

            <Link href={constants.routes.renewEnrollments.forgotPassword.path}>
              Esqueci minha senha
            </Link>
          </Grid>

          <Grid item xs={12}>
            <Subtitle className={classes.notice}>Atenção</Subtitle>
            <Label className={classes.textCenter}>
              Se o(a) estudante já está matriculado(a) na rede estadual, para
              utilizar o novo sistema pela primeira vez, e realizar sua
              rematrícula, será necessário comparecer à secretaria de sua escola
              para receber sua senha de acesso.
            </Label>
          </Grid>

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <Recaptcha onChange={setRecaptcha} />
          </Grid>
        </Grid>
      </Main>

      <Buttons extraClasses={classes.center}>
        <LoadingButton
          className={classes.margin}
          disabled={shouldDisableSearchButton()}
          loading={loading}
          onClick={tryToLogin(user, password)}
        >
          Consultar
        </LoadingButton>
      </Buttons>
    </>
  )
}

export default Form
