import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useState, useEffect } from 'react'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { errors as errorUtils, strings } from '../../../../../../utils'

import { FormControl } from '../../../../../../components/Forms'
import { Subtitle } from '../../../../../../components'
import { addressService, appService } from '../../../../../../services'

import Results from './Results'

const { limit, upcase } = strings

const handleText = text => upcase(limit(text, 250))

const defaultAddress = {
  cityId: undefined,
  cityName: '',
  complement: '',
  neighborhood: '',
  number: '',
  results: [],
  search: undefined,
  stateAcronym: undefined,
  stateId: undefined,
  street: '',
  streetId: undefined,
  weak: false,
  withoutNumber: false,
  zipcode: '',
}

const Search = ({
  address,
  chooseAddressFromSearch,
  errors,
  loading,
  validating,
  setLoading,
  update,
}) => {
  const hasError = errorUtils.hasError(errors)
  const errorMessage = errorUtils.errorMessage(errors)

  const [autocomplete, setAutocomplete] = useState('')
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [results, setResults] = useState(address.results || [])
  const [notFound, setNotFound] = useState(results.length === 0)
  const [search, setSearch] = useState(address.search || '')
  const [searched, setSearched] = useState(address.searched || false)
  const [timer, setTimer] = useState(null)

  const debounce = (fun, wait) => {
    return (...args) => {
      clearTimeout(timer)

      setTimer(
        setTimeout(() => {
          fun(...args)
        }, wait)
      )
    }
  }

  const shouldDisableSearchInput = () =>
    !!address.cityZipcode ||
    !address.stateId ||
    !address.cityId ||
    address.weak ||
    validating

  const canDisplayWeak = () =>
    !appService.getLocalPublicCall().mustResideOnZone && searched

  const shouldDisplayResultsAndWeak = () => !address.cityZipcode

  const findCityByName = name => cities.find(i => i.label === name)
  const findStateAcronym = id => states.find(i => i.value === id).label

  const resetSearch = () => {
    setSearched(false)
    setSearch('')
    setResults([])
  }

  const onChangeState = stateId => {
    update({
      ...defaultAddress,
      stateId,
      stateAcronym: findStateAcronym(stateId),
    })

    resetSearch()

    tryToGetAvailableCities(stateId)
  }

  const onChangeCity = cityName => {
    const { stateId, stateAcronym } = address

    const { value, zipcode } = findCityByName(cityName)

    update({
      ...defaultAddress,
      stateId,
      stateAcronym,
      cityId: value,
      cityName,
      cityZipcode: zipcode,
      zipcode,
    })

    resetSearch()
  }

  const manualInputProps = () => (address.cityName ? { shrink: true } : {})

  const tryToGetAvailableStates = async () => {
    setLoading(true)

    const {
      success,
      payload: { states: _states },
    } = await addressService.getAllStates()

    if (success) {
      setStates(_states)
    }

    setLoading(false)
  }

  const tryToGetAvailableCities = async stateId => {
    setLoading(true)

    const {
      success,
      payload: { cities: _cities },
    } = await addressService.getCitiesByState(stateId)

    if (success) {
      setCities(_cities)
    }

    setLoading(false)
  }

  const tryToFindAddress = async term => {
    const query = `${term}`
      .trim()
      .replace(/^RUA|^R\s|^AV\.?\s|^AVENIDA|^PC\s|^PÇ\s/g, '')

    if (!address.cityId || query.length < 3) return

    setLoading(true)

    const {
      success,
      payload: { addresses },
    } = await addressService.searchAddressByCityAndZipCodeOrStreet(
      address.cityId,
      query
    )

    if (success) {
      setResults(addresses)
      setNotFound(!addresses.length)
      setSearched(true)
      update({ searched: true })
    }

    setLoading(false)
  }

  useEffect(
    debounce(() => tryToFindAddress(search), 700),
    [search]
  )

  useEffect(() => {
    if (!address.stateId) {
      try {
        onChangeState(states.find(i => i.label === 'ES').value)
      } catch (e) {
        console.error('state not found: ', e)
      }
    }
  }, [states])

  useEffect(() => {
    if (address.stateId) {
      tryToGetAvailableCities(address.stateId)
    }
  }, [])

  useEffect(() => {
    tryToGetAvailableStates()
  }, [])

  const renderCityAutocomplete = () => {
    const citiesStr = cities.map(i => i.label)
    const value = address.cityName || ''

    return (
      <Grid item md={4} sm={8} xs={12}>
        <FormControl variant="outlined" error={hasError('cityId')}>
          <Autocomplete
            openText="Abrir"
            closeText="Fechar"
            clearText="Limpar"
            noOptionsText="Nada encontrado"
            disableClearable
            disabled={
              loading || !address.stateId || !cities.length || validating
            }
            id="cityId"
            options={citiesStr}
            value={value}
            inputValue={autocomplete}
            onChange={(_, city) => onChangeCity(city)}
            onInputChange={(_, val) => setAutocomplete(handleText(val))}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  error={hasError('cityId')}
                  InputLabelProps={manualInputProps()}
                  label="Cidade de residência"
                  variant="outlined"
                />
              )
            }}
          />

          {hasError('cityId') && (
            <FormHelperText>{errorMessage('cityId')}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    )
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Subtitle>Endereço do candidato</Subtitle>
        </Grid>

        <Grid item md={2} sm={4} xs={12}>
          <FormControl variant="outlined">
            <InputLabel htmlFor="stateId">Estado</InputLabel>
            <Select
              autoFocus
              disabled={loading || validating}
              id="stateId"
              label="Estado"
              onChange={e => onChangeState(e.target.value)}
              value={states.length ? address.stateId || '' : ''}
            >
              {states.map(i => (
                <MenuItem key={i.value} value={i.value}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {renderCityAutocomplete()}

        <Grid item md={6} xs={12}>
          <FormControl>
            <TextField
              disabled={shouldDisableSearchInput()}
              label="Buscar por CEP ou nome da rua"
              variant="outlined"
              name="search"
              value={search || ''}
              onChange={e => setSearch(handleText(e.target.value))}
            />
          </FormControl>
        </Grid>

        {shouldDisplayResultsAndWeak() && (
          <>
            <Grid item xs={12}>
              <Results
                addresses={results}
                choose={chooseAddressFromSearch}
                disabled={address.weak || validating}
                loading={loading}
                notFound={notFound}
                search={search}
                searched={searched}
                streetId={address.streetId}
              />
            </Grid>

            {canDisplayWeak() && (
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={address.weak || false}
                      disabled={validating}
                      onChange={e =>
                        update({ weak: e.target.checked, streetRef: undefined })
                      }
                      name="weak"
                      color="primary"
                    />
                  }
                  label="Não encontrei meu logradouro"
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </>
  )
}

export default Search
