import { strings } from './'

const { toString } = strings

const filterAnswerIds = relationships => {
  try {
    return relationships.answers.data.map(i => i.id)
  } catch (e) {
    console.error(e)
  }

  return []
}

const filterAnswers = (included, ids) => {
  try {
    return included
      .filter(i => i.type === 'answer' && ids.includes(i.id))
      .map(i => ({
        ...i.attributes,
        id: i.id,
      }))
  } catch (e) {
    console.error(e)
  }

  return []
}

const tryToFindClassification = included => {
  try {
    const { id, attributes } =
      included.find(i => i.type === 'classification') || {}

    if (id) {
      return {
        ...attributes,
        id,
      }
    }
  } catch (e) {
    console.error(e)
  }

  return {}
}

const tryToFindIntentOpt = (kind, included) => {
  try {
    const { id, attributes, relationships } =
      included.find(i => i.type === 'intent' && i.attributes.kind === kind) ||
      {}

    if (id) {
      const answerIds = filterAnswerIds(relationships)
      const answers = filterAnswers(included, answerIds)

      return {
        ...attributes,
        id,
        answers,
      }
    }
  } catch (e) {
    console.error(e)
  }

  return {}
}

const tryToFindQuizAnswers = (relationships, included) => {
  try {
    return filterAnswers(included, filterAnswerIds(relationships))
  } catch (e) {
    console.error(e)
  }

  return []
}

const fromApiToState = obj => {
  const {
    data: { attributes: i, relationships: r },
    included,
  } = obj

  const optOne = tryToFindIntentOpt('one', included)
  const optTwo = tryToFindIntentOpt('two', included)
  const optThree = tryToFindIntentOpt('three', included)

  return {
    ...i,
    classification: tryToFindClassification(included),
    student: {
      ...i,
      gender: toString(i.gender),
    },
    address: i,
    responsible: {
      name: i.rName,
      newsletter: i.rNewsletter,
      kind: i.rKind,
      cpf: i.rCpf,
      mobile: i.rMobile,
      altPhone: i.rAltPhone,
      email: i.rEmail,
    },
    intent: {
      ...i,
      optOne: {
        ...optOne,
        completed: !!optOne.id,
      },
      optTwo: {
        ...optTwo,
        completed: !!optTwo.id,
      },
      optThree: {
        ...optThree,
        completed: !!optThree.id,
      },
    },
    quiz: {
      answers: tryToFindQuizAnswers(r, included),
    },
  }
}

const subscription = {
  fromApiToState,
}
export default subscription
