import createTheme from '@material-ui/core/styles/createTheme'

const customizedTheme = () =>
  process.env.REACT_APP_IDV === 'cariacica'
    ? {
        palette: {
          background: {
            paper: '#fffef9',
            default: '#f7f7f7',
          },
          primary: {
            light: '#3bc4a4',
            main: '#38B598',
            dark: '#009D94',
          },
          secondary: {
            light: '#c5cca5',
            main: '#FAFFE1',
            dark: '#EEF3D8',
          },
          info: {
            light: '#CFCFCF',
            main: '#7B7B7A',
            dark: '#585857',
          },
          text: {
            primary: '#585857',
            secondary: '#7B7B7A',
            disabled: '#CFCFCF',
          },
          error: {
            main: '#C23A22',
          },
        },
      }
    : {
        palette: {
          background: {
            paper: '#fffef9',
            default: '#f9f9f9',
          },
          primary: {
            light: '#5081A0',
            main: '#366C88',
            dark: '#365A6D',
          },
          secondary: {
            light: '#B16C73',
            main: '#9C5C6A',
            dark: '#7B4A55',
          },
          info: {
            light: '#CFCFCF',
            main: '#7B7B7A',
            dark: '#585857',
          },
          text: {
            primary: '#585857',
            secondary: '#7B7B7A',
            disabled: '#CFCFCF',
          },
          error: {
            main: '#C23A22',
          },
        },
      }

const colors = {
  theme: createTheme(customizedTheme()),
}

export default colors
