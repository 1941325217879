import { renewEnrollmentApi } from '../api'

const tryToSubmit = async (groupStudentId, degree, curriculum) => {
  try {
    let response = await renewEnrollmentApi.create(
      groupStudentId,
      degree,
      curriculum
    )

    const { status } = response
    const responseBody = await response.json()

    if (status === 200) {
      return {
        success: true,
        payload: responseBody.data.attributes,
      }
    }

    if (status === 422) {
      return {
        success: false,
        error: responseBody.error,
      }
    }
  } catch {}

  return { success: false, payload: { error: null }, error: 'UnhandledError' }
}

const tryToCancel = async groupStudentId => {
  try {
    let response = await renewEnrollmentApi.cancel(groupStudentId)

    return {
      success: response.status === 200,
    }
  } catch {
    return { success: false, error: 'UnhandledError' }
  }
}

const get = async (user, password) => {
  try {
    const response = await renewEnrollmentApi.get(user, password)

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      return {
        success: true,
        payload: responseBody.data.map(i => i.attributes),
      }
    }
  } catch {}

  return {
    success: false,
    payload: {},
  }
}

const nextOffers = async groupStudentId => {
  try {
    const response = await renewEnrollmentApi.nextOffers(groupStudentId)

    const { ok, status } = response

    if (ok && status === 200) {
      const responseBody = await response.json()

      return {
        success: true,
        payload: responseBody.data.map(i => i.attributes),
      }
    }
  } catch {}

  return {
    success: false,
    payload: {},
  }
}

const getPdf = async groupStudentId => {
  try {
    const response = await renewEnrollmentApi.getPdf(groupStudentId)

    const { ok, status } = response

    if (ok && status === 200) {
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      let tag = document.createElement('a')

      tag.href = url
      tag.download = `comprovante-rematricula-${groupStudentId}.pdf`

      document.body.appendChild(tag)

      tag.click()
      tag.remove()
    }
  } catch (e) {
    console.error(e)
  }
}

const renewEnrollmentService = {
  get,
  getPdf,
  nextOffers,
  tryToSubmit,
  tryToCancel,
}

export default renewEnrollmentService
