import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { Alert, Subtitle, Label } from '../../../../../../components'
import { FormControl } from '../../../../../../components/Forms'

import {
  enums,
  errors as errorUtils,
  disabilities as disabilitiesUtils,
} from '../../../../../../utils'

const { trueAndFalse, disabilities, disorders, giftedness } = enums

const DEFAULT_LABEL = `Possui deficiência, transtorno do espectro autista ou altas habilidades/superdotação?`

const Disability = ({ classes, errors, validating, student, update }) => {
  const matchBreakpoint = useMediaQuery('@media (max-width: 800px)')

  const hasError = errorUtils.hasError(errors)
  const errorMessage = errorUtils.errorMessage(errors)

  const isChecked = value => (student.disabilities || []).includes(value)

  const label = () => (matchBreakpoint ? 'Deseja informar?' : DEFAULT_LABEL)

  const onChange = value => checked => {
    let disabilities = student.disabilities || []

    update({
      disabilities: disabilitiesUtils.update(disabilities, value, checked),
    })
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} className={classes.marginTop}>
        <Subtitle>Informações complementares</Subtitle>
      </Grid>

      {matchBreakpoint && (
        <Grid item xs={12}>
          <Label>{DEFAULT_LABEL}</Label>
        </Grid>
      )}

      <Grid item xs={12}>
        <FormControl variant="outlined" error={hasError('hasDisability')}>
          <InputLabel htmlFor="hasDisability">{label()}</InputLabel>
          <Select
            disabled={validating}
            error={hasError('hasDisability')}
            label={label()}
            id="hasDisability"
            value={
              typeof student.hasDisability === 'boolean'
                ? student.hasDisability
                : ''
            }
            onChange={e =>
              update({ hasDisability: e.target.value, disabilities: [] })
            }
          >
            {trueAndFalse.map(i => (
              <MenuItem key={i.value} value={i.value}>
                {i.label}
              </MenuItem>
            ))}
          </Select>

          {hasError('hasDisability') && (
            <FormHelperText>{errorMessage('hasDisability')}</FormHelperText>
          )}
        </FormControl>
      </Grid>

      {student.hasDisability && (
        <>
          <Grid item xs={12} className={classes.alert}>
            <Alert>
              <strong>Atenção!</strong>
              <br />
              Caso o candidato seja contemplado, será obrigatório apresentação
              de laudo médico comprobatório no ato da efetivação da matrícula.
            </Alert>
          </Grid>

          <Grid item xs={12}>
            <Subtitle className={classes.subtitleMargin}>Deficiência</Subtitle>
          </Grid>

          {disabilities.map(i => (
            <Grid
              item
              md={6}
              xs={12}
              key={i.value}
              className={classes.checkboxGrid}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={isChecked(i.value)}
                    disabled={validating}
                    onChange={e => onChange(i.value)(e.target.checked)}
                    name="hasDisability"
                    color="primary"
                  />
                }
                label={i.label}
              />
            </Grid>
          ))}

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <Subtitle className={classes.subtitleMargin}>
              Transtorno global do desenvolvimento
            </Subtitle>
          </Grid>

          {disorders.map(i => (
            <Grid
              item
              md={6}
              xs={12}
              key={i.value}
              className={classes.checkboxGrid}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={isChecked(i.value)}
                    disabled={validating}
                    onChange={e => onChange(i.value)(e.target.checked)}
                    name="hasDisability"
                    color="primary"
                  />
                }
                label={i.label}
              />
            </Grid>
          ))}

          <Grid item xs={12}>
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={12}>
            <Subtitle className={classes.subtitleMargin}>
              Altas habilidades/Superdotação
            </Subtitle>
          </Grid>

          {giftedness.map(i => (
            <Grid item xs={12} key={i.value} className={classes.checkboxGrid}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checkbox}
                    checked={isChecked(i.value)}
                    disabled={validating}
                    onChange={e => onChange(i.value)(e.target.checked)}
                    name="hasDisability"
                    color="primary"
                  />
                }
                label={i.label}
              />
            </Grid>
          ))}
        </>
      )}
    </Grid>
  )
}

export default Disability
