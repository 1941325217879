import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'

import { Main, PageHeader } from '../../components/Layout'
import { Label } from '../../components'
import { constants } from '../../utils'
import { appService } from '../../services'

const useStyles = makeStyles(() => ({
  center: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  divider: {
    margin: '24px 0 0',
  },
  margin: {
    marginTop: '12px',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    padding: '30px',
  },
}))

const Instructions = () => {
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    if (appService.shouldRedirectToHome()) {
      history.push(constants.routes.home.path)
    }
  }, [])

  return (
    <>
      <PageHeader
        title="Esqueci minha senha"
        buttonText="Voltar"
        onClick={() =>
          history.push(constants.routes.renewEnrollments.request.path)
        }
      />

      <Main className={classes.main}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Label className={classes.textCenter}>
              Neste momento, a recuperação da senha deve ser feita na secretaria
              da unidade escolar onde o(a) estudante está matrículado, para
              garantia da segurança de suas informações.
            </Label>
          </Grid>
        </Grid>
      </Main>
    </>
  )
}

export default Instructions
