import { BrowserRouter, Switch, Route } from 'react-router-dom'
import React from 'react'

import { constants } from './utils'
import LayoutBase from './components/Layout/LayoutBase'

import { ForgotPassword, Request } from './screens/RenewEnrollments'
import { Create, Search } from './screens/Subscriptions'
import { Home, Instructions, NotFound } from './screens'

const { routes } = constants

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={() => <Home />} />

      <Route
        exact
        path={routes.instructions.path}
        render={() => (
          <LayoutBase>
            <Instructions />
          </LayoutBase>
        )}
      />

      <Route
        exact
        path={routes.subscriptions.create.path}
        render={() => (
          <LayoutBase>
            <Create />
          </LayoutBase>
        )}
      />

      <Route
        exact
        path={routes.subscriptions.search.path}
        render={() => (
          <LayoutBase>
            <Search />
          </LayoutBase>
        )}
      />

      <Route
        exact
        path={routes.renewEnrollments.request.path}
        render={() => (
          <LayoutBase>
            <Request />
          </LayoutBase>
        )}
      />

      <Route
        exact
        path={routes.renewEnrollments.forgotPassword.path}
        render={() => (
          <LayoutBase>
            <ForgotPassword />
          </LayoutBase>
        )}
      />

      <Route path="*" render={() => <NotFound />} />
    </Switch>
  </BrowserRouter>
)

export default Router
