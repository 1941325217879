import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import React from 'react'
import { Helmet } from 'react-helmet'

import Routes from './routes'
import { colors } from './utils'

const App = () => {
  const idv = process.env.REACT_APP_IDV

  const idvTitle = () =>
    idv === 'cariacica'
      ? 'Chamada Escolar - Cariacica'
      : 'Chamada Escolar - Secretaria de Educação'

  const idvIcon = () =>
    idv === 'cariacica' ? '/favicon-cariacica.ico' : '/favicon.ico'

  const idvManifest = () =>
    idv === 'cariacica' ? 'manifest-cariacica.json' : 'manifest.json'

  const idvDescription = () =>
    idv === 'cariacica'
      ? 'Portal de incrição de alunos em escolas do cidade de Cariacica/ES - Chamada Escolar'
      : 'Portal de incrição de alunos em escolas do estado do Espírito Santo - Chamada Escolar'

  return (
    <>
      <Helmet>
        <title>{idvTitle()}</title>
        <link rel="icon" href={idvIcon()} />
        <link rel="manifest" href={idvManifest()} />
        <meta name="description" content={idvDescription()} />
      </Helmet>

      <ThemeProvider theme={colors.theme}>
        <CssBaseline />
        <Routes />
      </ThemeProvider>
    </>
  )
}

export default App
