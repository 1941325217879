import Button from '@material-ui/core/Button'
import makeStyles from '@material-ui/core/styles/makeStyles'
import React, { useState } from 'react'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import { dates } from '../../../../utils'

import { Buttons } from '../../../../components/Steps'
import { DescriptionItem, LoadingButton } from '../../../../components'
import { Main } from '../../../../components/Layout'
import { renewEnrollmentService } from '../../../../services'

const { format, now } = dates

const useStyles = makeStyles(() => ({
  icon: {
    fontSize: '60px',
    margin: '0 auto',
  },
  title: {
    margin: '24px 0 36px',
    textAlign: 'center',
  },
  center: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    width: '100%',
    padding: '30px',
  },
  margin: {
    marginTop: '12px',
  },
}))

const Done = ({ data, backToEnrollment }) => {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)

  const tryToGetPdf = async () => {
    setLoading(true)

    await renewEnrollmentService.getPdf(data.id)

    setLoading(false)
  }

  return (
    <>
      <Main className={classes.main}>
        <CheckCircleOutline className={classes.icon} />

        <Typography className={classes.title} variant="h5">
          Solicitação enviada com sucesso!
        </Typography>

        <Grid container spacing={1}>
          <DescriptionItem label="Aluno" value={data.name} md={9} sm={12} />

          <DescriptionItem
            label="Realizada em"
            value={data.requestedAt ? format(data.requestedAt) : now()}
            md={3}
            sm={12}
          />
        </Grid>
      </Main>

      <Buttons extraClasses={classes.center}>
        <LoadingButton
          disabled={loading}
          loading={loading}
          onClick={tryToGetPdf}
        >
          Imprimir Comprovante da Solicitação
        </LoadingButton>

        <Button
          className={classes.margin}
          variant="contained"
          color="default"
          onClick={backToEnrollment}
        >
          Voltar
        </Button>
      </Buttons>
    </>
  )
}

export default Done
